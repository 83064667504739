/**
 * Created by mac on 7/6/20
 */

var BlockMixer = function (options) {
    this.options = options || {
        reverse: 0
    };

    if (this.options.easy || cleverapps.config.wysiwygMode) {
        this.options.reverse = 0;
    }

    this.width = options.width || 10;
    this.height = options.height || 18;
};

BlockMixer.prototype.log = function (state) {
    BlockMixer.log(state);
};

BlockMixer.log = function (state) {
    for (var j = BlockMixer.calcHeight(state) - 1; j >= 0; j--) {
        var str = "";
        for (var i = 0; i < state.length; i++) {
            if (state[i].length > j) {
                str += state[i][j];
            } else {
                str += "_";
            }
        }

        console.log(str);
    }
};

BlockMixer.predictHardness = function (words2) {
    words = cleverapps.clone(words2);

    words.sort(function (a, b) {
        return b.length - a.length;
    });

    var price = 0;
    var letters = 0;
    for (var i = words.length - 1; i >= 0; i--) {
        price += letters;
        letters += words[i].length;
    }

    return price;
};

BlockMixer.removeFromState = function (state, indexes) {
    indexes.forEach(function (item) {
        for (var i = item.y + 1; i < state[item.x].length; i++) {
            state[item.x][i - 1] = state[item.x][i];
        }

        state[item.x] = state[item.x].slice(-1);
    });

    for (var i = state.length - 1; i >= 0; i--) {
        if (i === state.length) {
            continue;
        }
        if (state[i].length === 0) {
            state.splice(i, 1);
        }
    }
};

BlockMixer.prototype.calculate = function (option) {
    var words = option.words;
    var state = option.state;

    var total = 0;

    for (var t = words.length - 1; t >= 0; t--) {
        var curWord = words[t];

        var wordsCouldBeFound = 1;
        for (var i = 0; i < t; i++) {
            var word = words[i];

            if (BlockMixer.contains(state, word)) {
                wordsCouldBeFound++;
            }
        }

        var res = BlockMixer.contains(state, curWord, {
            details: true
        });

        if (!res) {
            // console.log('Error ', curWord);
            return -1;
        }

        // average number of options to find curWord
        // here we consider other states have the same calculation cost to avoid recursion
        total += res.operations / (wordsCouldBeFound + 1);

        BlockMixer.removeFromState(state, res.indexes);
    }

    return total;
};

BlockMixer.prototype.setSize = function (size) {
    this.width = size.width;
    this.height = size.height;
};

BlockMixer.prototype.mix = function (words, options) {
    this.wordAsArrayMap = {};
    this.wordAsArrayMapReversed = {};
    var positions = [];

    var wasOrder = [];
    for (var i = 0; i < (this.options.quick ? 1 : 10); i++) {
        var newWords = cleverapps.clone(words);

        newWords = cleverapps.Random.shuffle(newWords);
        newWords.sort(function (a, b) {
            return Math.round(b.length / 3) - Math.round(a.length / 3);
        });

        for (var j = 0; j < Math.ceil(newWords.length / 5); j++) {
            var swap1 = cleverapps.Random.random(0, Math.floor(newWords.length / 2) - 1);
            var swap2 = cleverapps.Random.random(Math.min(swap1 + 1, newWords.length - 1), newWords.length - 1);

            var tmp = newWords[swap1];
            newWords[swap1] = newWords[swap2];
            newWords[swap2] = tmp;
        }

        if (this.options.easy) {
            newWords.sort(function (a, b) {
                return a.length - b.length || a.localeCompare(b);
            });
        }

        var total = newWords.join("");
        if (wasOrder.indexOf(total) !== -1) {
            continue;
        }

        wasOrder.push(total);

        positions.push({
            score: 0,
            state: [],
            words: newWords
        });
    }

    for (var t = 0; t < words.length; t++) {
        var nextOptions = [];

        positions.forEach(function (option) {
            var newOptions = this.nextOption(t, option);

            for (var i = 0; i < newOptions.length; i++) {
                nextOptions.push(newOptions[i]);
            }
        }, this);

        // console.log("Finished with " + nextOptions.length);

        nextOptions.sort(function (optionA, optionB) {
            return optionA.score - optionB.score;
        });

        // console.log('nextOptions', nextOptions)

        positions = nextOptions.slice(0, this.options.quick ? 1 : BlockMixer.ALPHA_BETTA_LIMIT);
    }

    var position = cleverapps.Random.choose(positions);

    if (this.options.fillEmpty && position) {
        this.options.fillEmpty = false;

        var characters = cleverapps.unique(words.join("").split(""));
        for (var x = 0; x < position.state.length; x++) {
            while (position.state[x].length < this.height) {
                position.state[x] = position.state[x].concat(cleverapps.Random.choose(characters));
            }
        }
    }

    if (position) {
        var padLeft = Math.floor((this.width - position.state.length) / 2);

        for (i = 0; i < padLeft; i++) {
            position.state.unshift([]);
        }
    }

    if (options && options.details) {
        return position;
    }

    this.state = position && position.state;
    return this.state;
};

BlockMixer.isSameWords = function (save, words, options) {
    var count = {};
    words.forEach(function (column) {
        for (var i = 0; i < column.length; i++) {
            count[column[i].toLowerCase()] = (count[column[i].toLowerCase()] || 0) + 1;
        }
    });

    save.forEach(function (column) {
        for (var i = 0; i < column.length; i++) {
            if (!count[column[i].toLowerCase()]) {
                count[column[i].toLowerCase()] = 0;
            }
            count[column[i].toLowerCase()]--;
        }
    });

    for (var letter in count) {
        if (options.skip.indexOf(letter) !== -1) {
            continue;
        }
        if (count[letter] > 0) {
            return false;
        }
    }

    return true;
};

BlockMixer.prototype.getState = function () {
    return this.state;
};

BlockMixer.DIRECTIONS = [
    { x: 1, y: 0 },
    { x: 0, y: 1 },
    { x: 0, y: -1 },
    { x: -1, y: 0 }
];

BlockMixer.contains = function (state, word, options) {
    options = options || {};
    var operations = 0;

    var indexes = undefined;

    for (var x = 0; x < state.length; x++) {
        for (var y = 0; y < state[x].length; y++) {
            var firstValid = true;

            var ch = state[x][y];
            if (ch instanceof LetterBlock) {
                ch = ch.symbol;
            }

            if (word.charAt(0) !== ch) {
                firstValid = false;
            }

            if (firstValid || operations.details) {
                for (var i = 0; i < BlockMixer.DIRECTIONS.length; i++) {
                    var tx = x;
                    var ty = y;
                    var ok = true;
                    for (var j = 1; j < word.length; j++) {
                        tx += BlockMixer.DIRECTIONS[i].x;
                        ty += BlockMixer.DIRECTIONS[i].y;

                        if (tx < 0 || tx >= state.length) {
                            ok = false;
                            break;
                        }

                        if (ty < 0 || ty >= state[tx].length) {
                            ok = false;
                            break;
                        }

                        ch = state[tx][ty];
                        if (ch instanceof LetterBlock) {
                            ch = ch.symbol;
                        }
                        if (ch !== word.charAt(j)) {
                            ok = false;
                            if (!options.details) {
                                break;
                            }
                        }
                    }

                    operations += word.length;

                    if (firstValid && ok) {
                        if (options.details) {
                            tx = x;
                            ty = y;

                            indexes = [];
                            for (j = 0; j < word.length; j++) {
                                indexes.push({
                                    x: tx,
                                    y: ty
                                });
                                tx += BlockMixer.DIRECTIONS[i].x;
                                ty += BlockMixer.DIRECTIONS[i].y;
                            }
                        } else {
                            return true;
                        }
                    }
                }
            }
        }
    }

    if (options.details) {
        if (!indexes) {
            return undefined;
        }

        return {
            operations: operations,
            indexes: indexes
        };
    }

    return false;
};

BlockMixer.calcHeight = function (state) {
    var height = 0;
    for (var i = 0; i < state.length; i++) {
        if (state[i].length > height) {
            height = state[i].length;
        }
    }

    return height;
};

BlockMixer.prototype.geometryPenalty = function (state) {
    var width = state.length;
    if (width > this.width) {
        return Infinity;
    }
    var height = BlockMixer.calcHeight(state);
    if (height > this.height) {
        return Infinity;
    }
    height *= 1.25;
    var diff = Math.abs(height - width);

    diff = Math.round(diff / 2);

    return diff * diff + (height + width);
};

BlockMixer.intersectPenalty = 30;

BlockMixer.prototype.wordToArray = function (word, reverse) {
    if (reverse) {
        if (!this.wordAsArrayMapReversed[word]) {
            this.wordAsArrayMapReversed[word] = word.split("").reverse().join("");
        }

        return this.wordAsArrayMapReversed[word];
    } 
    if (!this.wordAsArrayMapReversed[word]) {
        this.wordAsArrayMapReversed[word] = word;
    }

    return this.wordAsArrayMapReversed[word];
};

BlockMixer.TYPE_LAY = 1;
BlockMixer.TYPE_SPLICE = 2;
BlockMixer.TYPE_ADD = 3;

BlockMixer.prototype.applyOption = function (state, nextWord, option) {
    var reverse = cleverapps.Random.nextDouble() < this.options.reverse;

    if (this.options.easy) {
        reverse = false;
    }

    var wordAsArray = this.wordToArray(nextWord, reverse);

    switch (option.type) {
        case BlockMixer.TYPE_SPLICE:
            // state.splice(option.x, 0, wordAsArray);
            state.splice(option.x, 0, wordAsArray.split("").reverse().join(""));
            return;
        case BlockMixer.TYPE_ADD:
            for (var i = 0; i < wordAsArray.length; i++) {
                var ch = wordAsArray[i];
                // state[option.x].splice(option.y, 0, ch);
            }
            state[option.x] = state[option.x].slice(0, option.y) + wordAsArray.split("").reverse().join("") + state[option.x].slice(option.y);
            return;
        case BlockMixer.TYPE_LAY:
            for (i = 0; i < wordAsArray.length; i++) {
                ch = wordAsArray[i];

                var offset = option.x + i;
                if (offset < 0) {
                    // state.splice(i, 0, [ch]);
                    state.splice(i, 0, ch);
                } else {
                    var pos = option.x + i;
                    if (option.x < 0) {
                        pos = i;
                    }
                    if (pos >= state.length) {
                        // state.push([ch]);
                        state.push(ch);
                    } else {
                        // state[i].splice(0, 0, ch);
                        // state[i].unshift(ch);
                        // state[i] = ch + state[i];

                        // state[offset].splice(option.y, 0, ch);
                        state[pos] = state[pos].slice(0, option.y) + ch + state[pos].slice(option.y);
                    }
                }
            }
    }
};

BlockMixer.prototype.verticalActions = function (state, wlen) {
    var res = [];

    for (var x = 0; x <= state.length; x++) {
        res.push({
            type: BlockMixer.TYPE_SPLICE,
            x: x,
            y: 0
        });
    }

    for (var i = 0; i < state.length; i++) {
        for (var j = 0; j <= state[i].length && (state[i].length - j) + wlen <= this.height; j++) {
            res.push({
                type: BlockMixer.TYPE_ADD,
                x: i,
                y: j
            });
        }
    }

    return res;
};

BlockMixer.prototype.horizontalActions = function (state, wlen) {
    if (state.length === 0) {
        return [{
            type: BlockMixer.TYPE_LAY,
            x: 0,
            y: 0
        }];
    }

    var res = [];

    var y = 0;

    if (y === 0) {
        var max = this.width - state.length;
        if (max > wlen - 1) {
            max = wlen - 1;
        }

        for (var n = -max; n < state.length; n++) {
            res.push({
                type: BlockMixer.TYPE_LAY,
                x: n,
                y: 0
            });
        }
    }

    for (y = 1; y < this.height; y++) {
        var sum = 0;

        for (var x = state.length - 1; x >= 0; x--) {
            if (x + wlen < state.length && state[x + wlen].length >= y) {
                sum--;
            }
            if (state[x].length >= y) {
                sum++;
            }

            if (sum === wlen) {
                res.push({
                    type: BlockMixer.TYPE_LAY,
                    x: x,
                    y: y
                });
            }
        }
    }

    return res;
};

// two segments [a, b], [c, d] intersect
BlockMixer.segmentsCross = function (a, b, c, d) {
    return b >= c && a <= d;
};

BlockMixer.checkIntersection = function (prevAction, prevWord, newAction, word) {
    var isPrevHorizontal = (prevAction.type === BlockMixer.TYPE_LAY);
    var isNewHorizontal = (newAction.type === BlockMixer.TYPE_LAY);

    var ax = prevAction.x;
    if (ax < 0) {
        ax = 0;
    }
    var bx = ax + (isPrevHorizontal ? (prevWord.length - 1) : 0);
    var cx = newAction.x;
    var dx = cx + (isNewHorizontal ? (word.length - 1) : 0);

    var ay = prevAction.y;
    var by = ay + (!isPrevHorizontal ? (prevWord.length - 1) : 0);
    var cy = newAction.y;

    if (isPrevHorizontal && isNewHorizontal) {
        if (ay < cy) {
            return false;
        }

        return BlockMixer.segmentsCross(ax, bx, cx, dx) && (ax < cx || bx > dx);
    }

    if (!isPrevHorizontal && newAction.type === BlockMixer.TYPE_SPLICE) {
        return false;
    }

    if (!isPrevHorizontal && newAction.type === BlockMixer.TYPE_ADD) {
        return ax === cx && (ay + 1 <= cy && cy <= by);
    }

    if (!isPrevHorizontal && isNewHorizontal) {
        return (cx <= ax && ax <= dx) && (ay + 1 <= cy && cy <= by);
    }

    // isPrevHorizontal && !isNewHorizontal
    if (newAction.type === BlockMixer.TYPE_SPLICE) {
        return (ax + 1 <= cx && cx <= bx);
    }

    // if (newAction.type === BlockMixer.TYPE_ADD) {
    return (ay >= cy) && (ax <= cx && cx <= bx);
};

BlockMixer.prototype.nextOption = function (index, parent) {
    var nextWord = parent.words[index];
    var verticalActions = this.verticalActions(parent.state, nextWord.length);
    var horizontalActions = this.horizontalActions(parent.state, nextWord.length);
    var actions = verticalActions.concat(horizontalActions);

    if (this.options.easy) {
        var maxx = (index === 0) ? 0 : (parent.words[index - 1].length - nextWord.length);
        actions = actions.filter(function (option) {
            return option.type === BlockMixer.TYPE_LAY && option.y === 0 && (maxx <= option.x && option.x <= 0);
        });
    }

    var res = [];
    actions.forEach(function (action) {
        var isIntersect = (index === 0) ? false : BlockMixer.checkIntersection(parent.action, parent.words[index - 1], action, nextWord);

        var newState = BlockMixer.cloneState(parent.state);

        this.applyOption(newState, nextWord, action);

        var score = parent.score + this.geometryPenalty(newState) + (!isIntersect ? BlockMixer.intersectPenalty : 0);

        // if (isIntersect && BlockMixer.contains(newState, parent.words[index - 1])) {
        //     console.log('isIntersect wrong', score, parent.action, action)
        //     BlockMixer.log(newState)
        // }

        if (score < Infinity) {
            res.push({
                score: score,
                state: newState,
                words: parent.words,
                action: action
            });
        }
    }, this);

    return res;
};

BlockMixer.cloneState = function (state) {
    return state.map(function (arr) {
        return arr;
    });
};

BlockMixer.areStatesSame = function (state1, state2) {
    if (state1.length !== state2.length) {
        return false;
    }

    for (var i = 0; i < state1.length; i++) {
        if (state1[i] !== state2[i]) {
            return false;
        }
    }

    return true;
};

BlockMixer.ALPHA_BETTA_LIMIT = 30;

BlockMixer.DIR = {
    VERTICAL: {
        x: 0,
        y: 1
    },

    HORIZONTAL: {
        x: 1,
        y: 0
    }
};

if (cleverapps.config.debugMode && 0) {
    var words = [
        "apricot", "banana", "apple", "pear", "pineapple", "dragonfruit", "watermelon", "orange", "raspberry", "blueberry",
        "cherry", "feijoa", "lemon", "grapefruit", "pomelo", "lime", "mandarin", "plum", "avocado", "blackberry",

        "blackcurrant", "cherimoya", "coconut", "cranberry", "durian", "figs", "gooseberry", "grapes", "kiwi", "longan",
        "mango", "mangosteen", "mulberry", "nectarine", "olive", "papaya", "passionfruit", "peach", "persimmon", "pomegranate",
        "raspberry", "soursop", "strawberry", "tamarind"];

    var mixer = new BlockMixer({
        width: 30,
        height: 30
    });

    var start = Date.now();

    mixer.mix(words);

    var finish = Date.now();
    console.log("Total words: " + words.length + " took " + (finish - start) / 1000);

    mixer.log(mixer.state);
}